
// TODO 待添加至菜单列表
import { defineComponent } from 'vue'
import { getPlaceProject, placeProject, deleteArchive } from '@/api/project'
import { ProjectDto } from '@/api/dto'
import { regressionFileExport } from '@/api/export'
import store from '@/store/index'
import { ElMessageBox } from 'element-plus'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
export default defineComponent({
    name: 'ProjectPlace',
    data () {
        return {
            fileTime: '',
            creationTime: '',
            pageSize: 10,
            pageNum: 1,
            total: 0 as number | undefined,
            tableData: [] as ProjectDto[],
            searchText: '',
            searchValue: '',
            count: {
                men: 0,
                count: 0,
                women: 0
            },
            selects: '' as string | string [],
            isDeletePopup: false
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async onDelete () {
            await deleteArchive(this.selects)
            await this.handleCurrentChange()
            this.$message.success('删除成功')
            this.isDeletePopup = false
        },
        handleCurrentChange () {
            getPlaceProject({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                searchText: this.searchValue,
                beginCreateTime: this.creationTime ? this.creationTime[0] : '',
                endCreateTime: this.creationTime ? this.creationTime[1] : '',
                beginArchivedTime: this.fileTime ? this.fileTime[0] : '',
                endArchivedTime: this.fileTime ? this.fileTime[1] : ''
            }).then(res => {
                this.total = res.total
                this.tableData = res.list
            })
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset () {
            this.searchText = ''
            this.creationTime = ''
            this.fileTime = ''
            this.search()
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        async recovery (id: string) {
            await placeProject({
                archivedIds: [id],
                isArchived: 0
            })
            this.$message.success('取消归档成功')
            this.handleCurrentChange()
        },
        async onExport () {
            // regressionFileExport
            const result = await regressionFileExport({
                ids: this.selects.toString()
            }, {
                loading: true
            })
            console.log(result)
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //         '/common/download?delete=true&fileName=' +
            //         result

            // this.$message.success('导出成功！')
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
            console.log(this.selects)
        }
    }
})
